.project {
  width: 300px;
  height: 250px;
  margin: 3px;
}

#project {
  background-color: rgb(42, 42, 42);
  color: white;
  text-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.projectname {
  position: relative;
  top: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  font-weight: bolder;
  color: white;
  cursor: pointer;
}



#project:hover {
  background-color: rgb(4, 71, 71);
   font-size: 0.9rem;
   font-weight:300;


}

